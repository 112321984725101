import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import './menumobile.css'

export function MenuMobile() {
  const history = useHistory();
  const [page, setPage] = useState('');

  function handleGoTo(routerName){
    history.push(routerName);
    //console.log(routerName);
  }

  return(
    <div className="links font-face-bs">
      <p className={page === "home" ? "linkactive" : "link"} onClick={() => {handleGoTo('/'); setPage("home")}}>Home </p>
      <p><span style={{color:'#f00'}}> | </span></p>
      <p className={page === "empresa" ? "linkactive" : "link"} onClick={() => {handleGoTo('/empresa'); setPage("empresa")}}>Vercelli </p>
      <p><span style={{color:'#f00'}}> | </span></p>
      <p className={page === "products" ? "linkactive" : "link"} onClick={() => {handleGoTo('/products'); setPage("products")}}>Produtos </p>
      <p><span style={{color:'#f00'}}> | </span></p>
      <p className={page === "contato" ? "linkactive" : "link"} onClick={() => {handleGoTo('/contato'); setPage("contato")}}>Contato </p>
    </div>
  )
}