import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {Container, Row, Col } from 'react-bootstrap/';

import { Form } from '@unform/web';
import Input from '../../components/Form/input';

import { Title } from '../../components/Title';

import { Header } from '../../components/Header';
import { useAuth } from "../../contexts/AuthContext";

import Image from "../../assets/bg.png";

import './login.css';

export function Login() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const { login, currentUser } = useAuth();
  const [ user, setUser ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ error, setError ] = useState('');

  async function handleLoginSubmit() {

    try{
      setError('')
      setLoading(true)

      await login(user, password)
      setUser("");
      setPassword("")

      setLoading(false)
    } catch {
      setError(`Complete os campos corretamente!`)
      setLoading(false)
    }   
  }

  useEffect(() => {
    let t = setTimeout(() => {
      if(currentUser){
        history.push("/orcamento");
      }
    }, 100);
    return () => clearTimeout(t);
    // eslint-disable-next-line
  }, [currentUser, history]);


  return(
    <Container fluid style={{backgroundImage:'url(' + Image + ')', backgroundSize:'cover', overflow:'hidden', height:'100vh', width:'100vw', overflowY: 'scroll',}}>
      <Header/>

        <Container className="formlogin font-face-bs">
        <Title title="Faça seu Login para acessar" subtitle="entre com seu e-mail e senha"/>
          <Row >
            <Col sm={12} style={{padding:20}}>
              Faça seu Login com E-mail e Senha
            </Col>
          </Row>
          <Row>  
            <Col sm={12}> 
                <Form onSubmit={handleLoginSubmit}>   
                    <Input 
                      type="email"
                      name="email"
                      placeholder="digite seu email"
                      onChange={event => setUser(event.target.value)}
                      value={user}
                    />            
                    <Input
                      name="password"
                      type="password"
                      placeholder="digite sua senha"
                      onChange={event => setPassword(event.target.value)}
                      value={password}
                    />
                      <button disabled={loading} type="submit" className="btn btn-gobike" style={{width:'100%'}}>
                        ENTRAR
                      </button>
                  </Form>
                  <p>{error}</p>           
              </Col>
          </Row>
        </Container>
          
    </Container>
    
  )
}