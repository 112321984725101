import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from "../../contexts/AuthContext";

import home from '../../assets/home.svg';
import user from '../../assets/user.png';

import './menu.css'

export function Menu() {
  const history = useHistory();
  const { logout, currentUser } = useAuth();
  const [ error, setError ] = useState('');

  async function handleLogoutSubmit() {
    try {
      setError('')
      await logout();
    } catch(error) {
      setError(`Falha no Logout`);
    }
  }

  const itensmenu = [
    {
      id:1,
      nome:"home",
      link:"/",
      icon:`${home}`
    },
    {
      id:2,
      nome:"vercelli",
      link:"/empresa",
      icon:""
    },
    {
      id:3,
      nome:"Produtos",
      link:"/products",
      icon:""
    },
    // {
    //   id:4,
    //   nome:"Categorias",
    //   link:"",
    //   icon:""
    // },
    {
      id:5,
      nome:"Orçamentos",
      link:"/orcamento",
      icon:""
    },
    {
      id:6,
      nome:"Contato",
      link:"/contato",
      icon:""
    },
  ]

  function handleGoTo(routerName){
    history.push(routerName);
    //console.log(routerName);
  }
  const result = itensmenu.filter(item => item.id !== 5);

  return (  
      <div className="menu-vercelli font-face-bs"> 

      <p>{error}</p>

      {currentUser ?
        <>
          {itensmenu.map((item, index) => (
            <div className="item-vercelli" key={item.id}>
              <p onClick={() => handleGoTo(item.link)}>{item.icon && <img src={item.icon} alt={item.nome}/>}{item.nome}</p>
            </div>                                  
          ))}
        </>
        :
        <>
          {result.map((item, index) => (
            <div className="item-vercelli" key={item.id}>
              <p onClick={() => handleGoTo(item.link)}>{item.icon && <img src={item.icon} alt={item.nome}/>}{item.nome}</p>
            </div>                                  
          ))}
        </>
      }

      {currentUser?
        <div className="item-vercelli">
          <p onClick={() => handleLogoutSubmit()}><img src={user} alt={"logout"}/>SAIR</p>
        </div>
        :
        <div className="item-vercelli">
          <p onClick={() => handleGoTo('/login')}><img src={user} alt={"login"}/>LOGIN</p>
        </div>
      }

    </div>   
  );
}
