import { useHistory } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

import bikeicon from '../../assets/bike-icon.svg';
import play from '../../assets/play.svg';
import './slidehome.css'

export function SliderHome() {
  const history = useHistory();

  function handleGoToBikes(){
    history.push("/products");
  }

  const apislide = "https://www.vercellibikes.com.br/api/slide/";

  return (
    <Carousel className="slide-home font-face-bs" style={{margin:0, padding:0}}>

      <Carousel.Item interval={3000} onClick={handleGoToBikes}>
          <img className="d-block w-100" src={`${apislide}slide1.jpg`} alt="Bike Vercelli Ettore" onClick={handleGoToBikes} style={{backgroundSize:'Cover'}} />
          <Carousel.Caption>
            <h5 className="tituloslide">VOCÊ JÁ TEM<br/> UMA VERCELLI?</h5>
            <h6 className="subtituloslide">LIBERDADE E PODER. ESCOLHA A SUA!</h6>
            <button className="btn-slide"><i className="fa fa-icon"> <img src={bikeicon} alt="icon-bike-shop" onClick={handleGoToBikes}/></i>BIKES VERCELLI</button>
            <button className="btn-slide-white"><i className="fa fa-icon"> <img src={play} alt="icon-play-video" onClick={handleGoToBikes}/></i>Assistir o Vídeo</button>
          </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={3000} onClick={handleGoToBikes}>
          <img className="d-block w-100" src={`${apislide}slide2.jpg`} alt="Bike Vercelli Ettore" onClick={handleGoToBikes}/>
          <Carousel.Caption>
            <h5 className="tituloslide">VERCELLI<br/>ETTORE 21V</h5>
            <h6 className="subtituloslide">ESSA é BRABA, TEM CORAGEM?</h6>
            <button className="btn-slide"><i className="fa fa-icon"> <img src={bikeicon} alt="icon-bike-shop" onClick={handleGoToBikes}/></i>BIKES VERCELLI</button>
            <button className="btn-slide-white"><i className="fa fa-icon"> <img src={play} alt="icon-play-video" onClick={handleGoToBikes}/></i>Assistir o Vídeo</button>
          </Carousel.Caption>
      </Carousel.Item>
      
    </Carousel>
  );
}
