import { Container } from 'react-bootstrap/';

import { Header } from '../../components/Header';
import { OrcamentoComponent } from '../../components/Orcamento';
import { ContatosComponent } from '../../components/Contatos';

import Image from "../../assets/bg.png";

export function Orcamento() {
  
  return (
    <Container fluid style={{backgroundImage:'url(' + Image + ')', backgroundSize:'cover', overflow:'hidden', height:'100vh', width:'100vw', overflowY: 'scroll',}}>

      <Header/>
      <Container>
        <OrcamentoComponent/>
      </Container>
      <Container>
        <ContatosComponent/>
      </Container>

    </Container>
)};
