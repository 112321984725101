import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap/';

import { useAuth } from "../../contexts/AuthContext";
import api from '../../services/api';

import { FaWhatsapp, FaBicycle, FaClipboardList, FaRegUserCircle, FaRegEnvelope, FaWeight, FaRegCheckCircle, FaRegClock } from 'react-icons/fa';

import './orcamento.css';

export function OrcamentoComponent() {
  const history = useHistory();
  const { currentUser } = useAuth();
  const [orcamento, setOrcamento] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if(!currentUser){
      history.push("/login");
    }
    return () => {};
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {    
    let isSubscribed = true;
      async function getOrcamentoSQL() {
        const response = await api.get(`/orcamento/`);
        if(isSubscribed){
          setOrcamento(response.data);
          setLoading(false); 
        }        
      };
      getOrcamentoSQL();    
    return () => isSubscribed = false
    // eslint-disable-next-line 
  }, [loading]);

  
  async function handleUpdateStatusTrue(id) {
    setLoading(true);
    await api.put(`/orcamento/${id}`,{
        status: "Respondido"
      });
    setLoading(false);
  }
  async function handleUpdateStatusFalse(id) {
    setLoading(true);
    await api.put(`/orcamento/${id}`,{
        status: "Pendente"
      });
    setLoading(false);
  }

  return(
    <>

    <h3 className="font-face-bs title-orders">Orçamentos Vercelli</h3>

      <Row className="font-face-bs list-orders vermelho" style={{marginBottom:3}}>
        <Col md={1}  sm={4}>
          <FaClipboardList/> Cód
        </Col>
        <Col md={2}  sm={4}>
        <FaRegUserCircle/> Nome
        </Col>  
        <Col md={2} sm={4}>
        <FaRegEnvelope/> Email
        </Col>   
        <Col md={2} sm={4}>
          <FaWhatsapp/> WhatsApp
        </Col>  
        <Col md={3} sm={4}>
        <FaBicycle/> Bike
        </Col>  
        <Col md={2} sm={4}>
        <FaWeight/> Status
        </Col>
      </Row>

      {loading === true ? <div className="textloading font-face-bs"> Aguarde...</div> :

      orcamento?.map((orcamento, i) => (
         <div key={orcamento.id} className="font-rbt">
          <Row className="list-orders">
            <Col md={1}>  
            {orcamento.id}
            </Col> 
            <Col md={2}>
              {orcamento.name}
            </Col>  
            <Col md={2}>
              {orcamento.email}
            </Col>   
            <Col md={2}>
              {orcamento.whatsapp}
            </Col>  
            <Col md={3}>
              {orcamento.bike}
            </Col>  
            <Col md={2}>
              {orcamento.status === "Respondido" ?
              <div style={{color:'#008000', cursor: 'pointer'}} onClick={() => handleUpdateStatusFalse(orcamento.id)}>
                <FaRegCheckCircle/> {orcamento.status}</div> 
              : <div style={{color:'#f90', cursor: 'pointer'}} onClick={() => handleUpdateStatusTrue(orcamento.id)}>
                <FaRegClock/> {orcamento.status}
              </div>
              }
            </Col>
          </Row>
        </div>
      ))}
    </>
  );
}