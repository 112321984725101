import React, { useState, useRef } from 'react';
import Lottie from 'react-lottie-player';
import { Row, Col, Modal } from 'react-bootstrap/';
import * as Yup from 'yup';
import api from '../../services/api';

import { Form } from '@unform/web';
import Input from '../../components/Form/input';

import { Breadcrumbs } from '../../components/Breadcrumbs';
import { FaPhoneSquareAlt } from 'react-icons/fa';
import lottieJson from '../../lotties/contact.json';

import './contato.css';

export function ComponenteContato() {
  const [modalShow, setModalShow] = useState(false);

  const formRef = useRef(null);

  function MyVerticallyCenteredModal(props) {
    
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="font-face-bs">Sucesso! Em breve retornaremos.</h4>
          </Modal.Title>
        </Modal.Header>        

        <Modal.Body>   
          <h4 className="font-face-bs msgsucess">Sua mensagem foi enviada com sucesso! </h4>
        </Modal.Body>
      </Modal>
    );
  }

  async function handleSubmit(data, {reset}) {

    try{
      const schema = Yup.object().shape({
      name: Yup.string()
      .required("* obrigatório"),
      email: Yup.string().email()
      .required("* obrigatório"),
      whatsapp: Yup.string()
      .required("* obrigatório"),
      message: Yup.string()
      .required("* obrigatório"),
    });

    await schema.validate(data, {
      abortEarly:false,
    })

    const { name, email, whatsapp, message } = data;

    await api.post(`/contatos`, {
      name, 
      email, 
      whatsapp, 
      message,
      status: "Pendente"
     });
     
      //console.log(data);
      setModalShow(true);
      formRef.current.setErrors({});

      setTimeout(() => {
        setModalShow(false);        
      }, 4000);

     reset();  

    }catch(err){
      if(err instanceof Yup.ValidationError){
        const errorMessages = {};
        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        })
        formRef.current.setErrors(errorMessages);
      }
    }
    
  }

  const openInNewTab = url => {
    // 👇️ setting target to _blank with window.open
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  
  return (
    <>
      <Row>
        <Col sm={12}>
          <Breadcrumbs home={"HOME"} linkhome={'/'} category={"VERCELLI"} model={"CONTATO"}/>
        </Col>
      </Row>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Row>
        <Col sm={12} className="cordotitulocontato">
        <h3 className="font-face-bs">Fale com a Vercelli</h3>
          <p className="subdotitulocontato">Hoje é um bom dia! Faremos um ótimo atendimento. Preencha os dados abaixo ou ligue 
            <span onClick={() => openInNewTab('tel://1147610475')} style={{color:"#f00", fontWeight:'bold', cursor: 'pointer'}}> <FaPhoneSquareAlt/> (11) 4761-0475</span>
          </p>
        </Col>
      </Row>
      <Row style={{marginTop:20,  paddingBottom:100}}>
        <Col sm={5} className="animacao_contato">
          <Lottie
            loop
            animationData={lottieJson}
            play
            style={{ maxWidth: 300}}
          />
        </Col>
        <Col sm={7}>    
          <Form onSubmit={handleSubmit} className="form" ref={formRef}>
            <Input label="Nome" name="name"/>
            <Input label="E-mail" name="email"/>
            <Input label="WhatsApp" name="whatsapp"/>
            <Input label="Mensagem" name="message"/>
            <button type="submit" className="btn btn-gobike" style={{width: '100%'}}>Enviar</button>
          </Form>
        </Col>
      </Row>      
   </>

)};