import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";

import { EffectFade } from 'swiper';

import 'swiper/css/effect-fade';
import "swiper/css";
import "./style.css";

import vercelli_ettore from '../../assets/vercelli_ettore.jpg'
import vercelli_etorre_zoom from '../../assets/vercelli_etorre_zoom.jpg'

export function SliderMobile() {

  const history = useHistory();

  function handleGoToBikes(){
    history.push("/products");
  }

  function handleGoToBike(codigo){
    history.push({
      pathname: '/bike',
      state: { select: codigo }
    });
  }

  return(
    <Swiper modules={[EffectFade]} effect="fade" className="mySwiper">
        <SwiperSlide  onClick={handleGoToBikes} >
          <img className="d-block w-100" src={vercelli_etorre_zoom} alt="Vercelli Produtos" />
        </SwiperSlide>
        <SwiperSlide  onClick={() => handleGoToBike(84940)}>
          <img className="d-block w-100" src={vercelli_ettore} alt="Vercelli Ettore" />
        </SwiperSlide>
      </Swiper>
  )

}