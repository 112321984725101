//import logo from '../../assets/logo.svg';
import { useHistory } from 'react-router-dom';
import {Row, Col } from 'react-bootstrap/';

import { LogoAnimation } from '../../components/LogoAnimation';
import { Menu } from '../Menu';
// import hamburguer from '../../assets/hamburguer.svg';

import './header.css'

export function Header() {
  const history = useHistory();

  function handleGoTo(routerName){
    history.push(routerName);
   // console.log(routerName);
  }

  return (
      <Row className="header-vercelli">

          <Col sm={4} className="logoemenu">
            {/* <img src={logo} className="logo-vercelli" alt="logo vercelli bikes"/> */}
            <div onClick={() => handleGoTo('/')} style={{cursor: 'pointer'}}>
              <LogoAnimation/>
            </div>            
            {/* <div className="iconmenu"><img src={hamburguer} alt="menu-hamburguer"/></div> */}
          </Col>

          <Col sm={8} style={{ margin:0, paddingRight:50 }}>
            <Menu />
          </Col>  

      </Row>
  );
}
