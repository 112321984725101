import Routes from './routes';
import { AuthProvider } from './contexts/AuthContext';
import { CategoriaProvider } from './contexts/CategoriasContext';
//import { BikesProvider } from './contexts/BikesContext';
//<BikesProvider></BikesProvider>
import './app.css'

function App() {
  return (
    <AuthProvider> 
      <CategoriaProvider>       
            <Routes/>        
      </CategoriaProvider>
    </AuthProvider>
    
  );
}

export default App;
