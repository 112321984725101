import Lottie from 'react-lottie-player'
import lottieJson from '../../lotties/logo-vercelli';

export function LogoAnimation(){

  return(
    <Lottie
      loop
      animationData={lottieJson}
      play
      style={{ width: 370}}
    />
  )
};

//https://github.com/mifi/react-lottie-player