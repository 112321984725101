import { Container } from 'react-bootstrap/';

import { Header } from '../../components/Header';
import { ComponenteContato } from '../../components/Contato';
import { MenuMobile } from '../../components/MenuMobile';

import Image from "../../assets/bg.png";

export function Contato() {
  
  return (
    <Container fluid style={{backgroundImage:'url(' + Image + ')', backgroundSize:'cover', overflow:'hidden', height:'100vh', width:'100vw', overflowY: 'scroll',}}>
      
      <Header/>
      <MenuMobile/>
      
      <Container>
        <ComponenteContato/>
      </Container>

    </Container>

)};