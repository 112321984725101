import {useState, useEffect} from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react'
//https://auto-animate.formkit.com/#usage-react
import api from '../../services/api';

import { Card } from '../../components/Products/Card/';
import { LogoAnimation } from '../../components/LogoAnimation';

import './products.css';

export function Products() {
  
  const [animationParent] = useAutoAnimate({duration:300});

  const [loading, setLoading] = useState(true);
  const [produtos, setProdutos] = useState([]);
  const [category, setCategory] = useState('');
  const [filteredbikes, setFilteredbikes] = useState([]);
  const [categorizados, setCategorizados] = useState([]);
  const [busca, setBusca] = useState('');

  useEffect(() => {
    let isSubscribed = true
   
    async function getProdutos() {
      const response = await api.get(`/produtos/`);
      if (isSubscribed) {
        setProdutos(response.data);
        setLoading(false);
      }
    };
    getProdutos();
    return () => isSubscribed = false
  }, []);

  const produtosdestaque = produtos.filter((produto) => produto.destaque === 1)

  useEffect(() => { 
    category !== '' ? setCategorizados(produtosdestaque.filter((produto) => produto.categoria.name === category)) : setCategorizados(produtosdestaque)
  // eslint-disable-next-line
  }, [category, busca]);

 
  useEffect(() => { 
    if(busca.length){
      setLoading(true);
      setCategory(""); 
      setFilteredbikes(produtosdestaque.filter(bike => bike.name.includes(busca)));
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }  
  // eslint-disable-next-line  
   }, [busca]);

  function handleGoToBikes(){
    setCategory(""); 
    setBusca("");
  }

  return(
    <div className="product__wrapper" style={{marginTop:30, paddingBottom:100}} >

      <div className="categories font-face-bs">
        <p className={category === "" ? "catactive" : "cat"} onClick={() => {setCategory(""); setBusca("")}}>Todas </p>
        <p><span style={{color:'#f00'}}> | </span></p>
        <p className={category === "mtb" ? "catactive" : "cat"}onClick={() => {setCategory("mtb") ; setBusca("")}}>Montain Bike</p>
        <p><span style={{color:'#f00'}}> | </span></p>
        <p className={category === "speed" ? "catactive" : "cat"}onClick={() => {setCategory("speed"); setBusca("")}}>Speed ( Road )</p>
      </div>

      <div className="search font-face-bs">

        <input 
        name="search" 
        type="text" 
        placeholder="Buscar..." 
        onChange={e => setBusca(e.target.value)}
        value={busca}
        />

      </div>


      <div className="card__container" ref={animationParent}> 

        {loading ? <div className="textloading font-face-bs"><LogoAnimation/></div> :
          
          // busca.length > 0 ? 
          // filteredbikes.map((p, index) => (
          //   <Card key={index} product={p}/>
          // )) 
          // : category !== '' ? 
          // categorizados?.map((p, index) => (
          //   <Card key={index} product={p}/>
          // )) 
          // :produtosdestaque?.map((p, index) => (
          //   <Card key={index} product={p}/>
          // ))

          (busca.length > 0 ? filteredbikes : category !== '' ? categorizados : produtosdestaque).map((p, index) => (
            <Card key={index} product={p}/>
          ))

        }





      </div>

      <div id="buttonhomemaismodelos">


        {category !== '' && 
        <button 
            className="btn btn-gobike"
            onClick={handleGoToBikes}          
            style={{width:'50%', marginTop: 50}}>
          Todos Modelos
        </button>
        }


      </div>
    </div>

  )
}