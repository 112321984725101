import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap/';
import './breadcrumbs.css';

export function Breadcrumbs(props) {

  const history = useHistory();

  function handleGoToHome(){
    history.push(props.linkhome);
  }
  function handleGoToProducts(){
    history.push(props.linkproducts);
  }

  return (

    <Row>
      <Col sm={5}>
        <div className="breadcrumbs font-face-bs">
          <span onClick={() => handleGoToHome()}>{props.home}</span> &gt; <span onClick={() => handleGoToProducts(props.products)}>{props.category}</span> &gt; <span style={{color:'#f00'}}>{props.model}</span>
        </div>
      </Col>
    </Row>

  );
}
