import { BrowserRouter, Route, Switch } from "react-router-dom";

import { Login } from './screens/Login';
import { Home } from './screens/Home';
import { Empresa } from './screens/Empresa';
import { ProductsScreen } from './screens/Products';
import { BikeScreen } from './screens/Bike';
import { Contato } from './screens/Contato';

import { Orcamento } from './screens/Orcamento';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/empresa" exact component={Empresa} />
        <Route path="/products" exact component={ProductsScreen}/>
        <Route path="/bike/:id" exact component={BikeScreen}/>
        <Route path="/contato" exact component={Contato} />

        <Route path="/login" exact component={Login} />
        <Route path="/orcamento" exact component={Orcamento} />

      </Switch>
    </BrowserRouter>
  )
};