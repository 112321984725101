import { useHistory } from 'react-router-dom';

import star from '../../../assets/star.svg';
import share from '../../../assets/share.svg';
import love from '../../../assets/love.svg';
import notification from '../../../assets/notification.svg';

import { FaShoppingBasket, FaInfoCircle } from 'react-icons/fa';

import './card.css'

export function Card(props) {

  const history = useHistory();
// eslint-disable-next-line
  const { id, codigo, categoria, name, preco, estoque, atributos } = props.product;
  const url = "https://vercellibikes.com.br/api/produtos/images/mini/";

  const stars = [
    { 
      name: 'star',
      id:1
    },    
    { 
      name: 'star',
      id:2
    },
    { 
      name: 'star',
      id:3
    },
    { 
      name: 'star',
      id:4
    },

  ]

  function price(x) {
    return Number.parseFloat(x).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(",", ".");
  }

  function handleGoToBike(codigo, id){
    history.push({
      pathname: `/bike/${id}`,
      state: { select: codigo }
    });
  }

  return(

    <div className="card__wrapper">

        <div className="card">

          <div className="haeder__card font-face-bs">

            <div className="stars">          
              {stars.map((ranking) => (        
                <img src={star} alt="star" key={ranking.id}/>   
              ))}
            </div>

            <div className="redes">
              <img src={love} alt="love"/> <img src={share} alt="share"/> <img src={notification} alt="notification"/>
            </div>

          </div>

          <div className="category font-face-bs">
            <p>Cód: <span style={{color:"#f00"}}>{codigo}</span></p>
            <p>Cat: {categoria?.name}</p>
          </div>

          <div className="imgproduct" onClick={() => handleGoToBike(codigo, id)}>
            <img style={{ maxHeight:150}} src={`${url}${estoque?.img_src}` ? `${url}${estoque?.img_src}` : `${url}carregando.png`} alt={name} 
            
            onError={(e) => {
              e.target.onerror = 404
              e.target.onerror = null;
              e.target.src = 'https://vercellibikes.com.br/api/produtos/images/carregando.png';
            }}   
            /> 
          </div>

          <div className="font-face-bs" style={{cursor: 'pointer'}}>
            <h2 onClick={() => handleGoToBike(codigo, id)}>{name}</h2>
          </div>

          {/* <div className="colorsize">
            <h5 className="font-face-bs">{color}</h5>
            <p className="font-face-bs">Tamanho: <span style={{color:'#000'}}>{size}"</span></p>
          </div> */}


          <div className="preco_sugerido">
            <span className="font-face-bs">Preço Sugerido</span>
            <h5 className="font-face-bs"> </h5>
          </div>

          <div className="footer__card">
            {preco !== 0 ? <h5 className="font-face-bs"> {price(preco)}</h5> : ""}
            <p className="font-face-bs">Estoque: <span style={{color:'#f00'}}>SIM</span></p>
          </div>


          <div className="button__card font-face-bs">
            <button className="btn btn-gobike"  onClick={() => {handleGoToBike(codigo, id)}}>
              <FaShoppingBasket style={{marginRight:10}}/> comprar
            </button>
              <button className="btn btn-mais-info" style={{backgroundColor:'#fff', borderColor:'#222', borderWidth:'1', marginBottom:10}}  
              onClick={() => handleGoToBike(codigo, id)}>
              <FaInfoCircle style={{marginRight:10}}/> mais detalhes
            </button>
          </div>

        </div>

    </div>
  );
}


        