import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap/';

import { useAuth } from "../../contexts/AuthContext";
import api from '../../services/api';

import { FaWhatsapp, FaEnvelope, FaClipboardList, FaRegUserCircle, FaRegEnvelope, FaWeight, FaRegCheckCircle, FaRegClock } from 'react-icons/fa';



export function ContatosComponent() {
  const history = useHistory();
  const { currentUser } = useAuth();
  const [ contatos, setContatos] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if(!currentUser){
      history.push("/login");
    }
    return () => {};
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {    
    let isSubscribed = true;
      async function getContatosSQL() {
        const response = await api.get(`/contatos`);
        if(isSubscribed){
          setContatos(response.data);
          setLoading(false); 
        }        
      };
      getContatosSQL();    
    return () => isSubscribed = false
    // eslint-disable-next-line 
  }, [loading]);

  
  async function handleUpdateStatusTrue(id) {
    setLoading(true);
    await api.put(`/contatos/${id}`,{
        status: "Respondido"
      });
    setLoading(false);
  }
  async function handleUpdateStatusFalse(id) {
    setLoading(true);
    await api.put(`/contatos/${id}`,{
        status: "Pendente"
      });
    setLoading(false);
  }

  return(
    <>

    <h3 className="font-face-bs title-orders">Contatos Vercelli</h3>

    <Row className="font-face-bs list-orders vermelho" style={{marginBottom:3}}>
      <Col md={1}>
        <FaClipboardList/> Cód
      </Col>
      <Col md={2}>
       <FaRegUserCircle/> Nome
      </Col>  
      <Col md={2}>
       <FaRegEnvelope/> Email
      </Col>   
      <Col md={2}>
        <FaWhatsapp/> WhatsApp
      </Col>  
      <Col md={3}>
       <FaEnvelope/> Mensagem
      </Col>  
      <Col md={2}>
       <FaWeight/> Status
      </Col>
    </Row>

      {loading === true ? <div className="textloading font-face-bs"> Aguarde...</div> :

      contatos?.map((conatos, i) => (

         <div key={conatos.id} className="font-rbt">
          <Row className="list-orders">
            <Col md={1}>  
            {conatos.id}
            </Col> 
            <Col md={2}>
              {conatos.name}
            </Col>  
            <Col md={2}>
              {conatos.email}
            </Col>   
            <Col md={2}>
              {conatos.whatsapp}
            </Col>  
            <Col md={3}>
              {conatos.message}
            </Col>  
            <Col md={2}>
              {conatos.status === "Respondido" ?
              <div style={{color:'#008000', cursor: 'pointer'}} 
              onClick={() => handleUpdateStatusFalse(conatos.id)}>
                <FaRegCheckCircle/> {conatos.status}</div> 
              : 
              <div style={{color:'#f90', cursor: 'pointer'}} 
              onClick={() => handleUpdateStatusTrue(conatos.id)}>
                <FaRegClock/> {conatos.status}
              </div>
              }
            </Col>
          </Row>
        </div>
      ))}

    </>

  );
}