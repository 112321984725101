import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Tab, Tabs, Carousel, Button, Modal, Form } from 'react-bootstrap/';
// eslint-disable-next-line 
import CarouselMulti from 'react-multi-carousel';

import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

import { Form as Formulario } from '@unform/web';

import api from '../../services/api';
import Input from '../../components/Form/input';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { LogoAnimation } from '../../components/LogoAnimation';

import { FaWhatsapp, FaHeart, FaShareAlt, FaBell, FaRegBookmark, FaRegEye, FaStar } from 'react-icons/fa';


import './bike.css';

export function Bike() {
  // eslint-disable-next-line 
  const location = useLocation();
  const { id } = useParams();

  const formRef = useRef(null);

  const { currentUser } = useAuth();
  
  // useEffect(() => {
  //   setCodigo(location?.state?.select);   
  // }, [location]);

 // const [allbikes, setAllBikes] = useState([]);
  const [bike, setBike] = useState([]);
  const [select, setSelect] = useState(id);

  const [bikemodel, setBikeModel] = useState('');
  const [color, setColorBike] = useState('');
  const [size, setSizeBike] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingimage, setLoadingImage] = useState(true);

  const [lovely, setLovely] = useState(false);
  const [eye, setEye] = useState(false);
  const [bookmarked, setBookmarked] = useState(false);
  const [notification, setNotification] = useState(false);

  const [updatebox, setUpdateBox] = useState(false);
  const [msgsucess, setMsgSucess] = useState(false);

  const [bikes, setBikes] = useState([]);

  useEffect(() => {
    let isSubscribed = true

    async function getBike() {
      const response = await api.get(`/produto/${select}`);
      if (isSubscribed) {
        setBike(response.data);
        setLoadingImage(false);
      }
    };
    getBike();

    async function getBikes() {
      const response = await api.get('/produtos/');
      if (isSubscribed) {
        setBikes(response.data);
        setLoading(false);
      }
    };
    getBikes();
    return () => isSubscribed = false
  }, [select, loading, loadingimage]);



  const bikesmesmonome = bikes.filter((outrasbikes) => outrasbikes?.name === bike[0]?.name )

  const bikescomamesmacor = bikesmesmonome.filter(cores => cores.estoque?.color_text === bike[0]?.estoque?.color_text )

  const coresdasoutrasbikes = bikesmesmonome.filter(coresdiferentes => coresdiferentes?.estoque?.estoque >= 0 )

  const outrostamanhosdisponiveis = bikescomamesmacor.filter(sizes =>  sizes?.estoque?.estoque >= 0)

  const umacordecadabike = coresdasoutrasbikes.filter(umcordecada => umcordecada.estoque?.size === bike[0]?.estoque?.size)


// eslint-disable-next-line 
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 8
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3
    }
  };
  

  setTimeout(() => {
    formRef.current?.setData(bike[0]);
  }, 10)


  const apibikes = "https://vercellibikes.com.br/api/produtos/images/";
  //https://www.onlineimagetool.com/pt/resize-png-jpg-webp-gif

  const apiatributos = "https://vercellibikes.com.br/api/produtos/atributos/";

  const stars = [
    { 
      name: 'star',
      id:1
    },    
    { 
      name: 'star',
      id:2
    },
    { 
      name: 'star',
      id:3
    },
    { 
      name: 'star',
      id:4
    },
  ]

  const [modalShow, setModalShow] = useState(false);

  function MyVerticallyCenteredModal(props) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [whatsapp, setWhatsApp] = useState('');
    
    const [msgsucess, setMsgsucess] = useState(false);

    // async function enviaremail() {
    //   //await api.get(`/send-email-bike-client?email=${email}&name=${name}&bike=${bikemodel}`);
    //   await api.get(`/send-email?email=${email}&name=${name}`);

    //   setName('');
    //   setEmail('');
    //   setWhatsApp('');
    //   setModalShow(false);
    // }

    async function cadastrarOrcamento() {
      await api.post(`/orcamento`, {
        name, 
        email,
        whatsapp,
        bike: `${bikemodel}, ${size}, ${color}`,
        status:"Pendente"
      });

      setName('');
      setEmail('');
      setWhatsApp('');
      setMsgsucess(true);

      setTimeout(() => {
        setModalShow(false);
        setMsgsucess(false);
      }, 4000);
      
    }
  
    async function handleSubmit(event) {  
      event.preventDefault()      
      if(name && email && whatsapp !== ''){
        cadastrarOrcamento()
      }else{
        alert("Nome ou email inválido!")
      }
    }
  
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="font-face-bs">Receber detalhes da <span style={{color:'#f00'}}>{bikemodel}, {color}, tam.: {size}"</span></h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>   
          {msgsucess? <h4 className="font-face-bs msgsucess">Sua mensagem foi enviada com sucesso! </h4> :  
          <>
            <p className="font-rbt">Digite seu nome, E-mail e WhatsApp. Em breve, você receberá o orçamento.</p>

            <Form onSubmit={handleSubmit} className="form-vercelli font-rbt">
              <Row>
                <Col sm={12} lg={3}>
                  <Form.Control 
                    type="text" 
                    placeholder="digite seu Nome" 
                    onChange={event => setName(event.target.value)} 
                    value={name}
                  />
                </Col>
                <Col sm={12} lg={3}>            
                  <Form.Control 
                    type="text" 
                    placeholder="digite seu E-mail" 
                    onChange={event => setEmail(event.target.value)} 
                    value={email}
                  />            
                </Col>
                <Col sm={12} lg={3}>
                  <Form.Control 
                    type="text" 
                    placeholder="digite seu WhatsApp" 
                    onChange={event => setWhatsApp(event.target.value)} 
                    value={whatsapp}
                  />
                </Col>
                <Col sm={12} lg={3}>  
                  <Button 
                    type="submit" 
                    variant="danger" 
                    style={{backgroundColor:"#00a877", borderColor:"#00a877", BorderWidth:0, width:'100%'}}>
                    <FaWhatsapp/> Pedir Orçamento
                  </Button> 
                </Col>
              </Row>      
            </Form>          
         </>}

        </Modal.Body>
        {/* 
        <Modal.Footer>
          <Button onClick={props.onHide} style={{marginLeft:10, backgroundColor:"#f00"}} variant="danger"  >Fechar</Button>
        </Modal.Footer> 
        */}
      </Modal>
    );
  }

  function price(x) {
    return Number.parseFloat(x).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).replace(",", ".");
  }

  function like(){
    setLovely(prevState => !prevState);
  }
  function eyeled(){
    setEye(prevState => !prevState);
  }
  function marked(){
    setBookmarked(prevState => !prevState);
  }

  function showupdatebox(){
    setUpdateBox(prevState => !prevState);
  }

  function filtraBike(vemporparametro){
    setSelect(vemporparametro);
  }

  async function handleSubmit(data, {reset}) {

    setMsgSucess('')
    setLoading(true)

    console.log(data)

    const { 
      codigo,
      name,
      description,
    
      home,
      destaque,
  
      preco,
      desconto,
  
      estoque:{
        estoque,
        color_text,
        color_one_hexa,
        color_two_hexa,
        color_three_hexa,
        size,
      },
      img_src,

      atributos:{ 
        quadro,
        suspensao,
        guidao,
        manopla,
        movimento_direcao,
        movimento_central,
        alavanca,
        cambio_dianteiro,
        cambio_traseiro,
        freio,
        selim,
        canote,
        pedivela,
        corrente,
        cassete,
        pedal,
        pneu_traseiro,
        roda_traseira,
        cubo_traseiro,
        pneu_dianteiro,
        roda_dianteira,
        cubo_dianteiro
      }

    } = data;

    await api.put(`/produto/${select}`, {
      codigo,
      name,
      description,
  
      color_text,
      color_one_hexa, 
      color_two_hexa, 
      color_three_hexa,
  
      size,
  
      home,
      destaque,
  
      preco,
      desconto,
  
      estoque,
      img_src,
  
      quadro,
      suspensao,
      guidao,
      manopla,
      movimento_direcao,
      movimento_central,
      alavanca,
      cambio_dianteiro,
      cambio_traseiro,
      freio,
      selim,
      canote,
      pedivela,
      corrente,
      cassete,
      pedal,
      pneu_traseiro,
      roda_traseira,
      cubo_traseiro,
      pneu_dianteiro,
      roda_dianteira,
      cubo_dianteiro
    }).then((response) => {
      console.log(response);
      if(response.status === 201){
        setMsgSucess('Bike Atualizada com Sucesso!')
        setLoading(false);

        setTimeout(() => {
          setMsgSucess('')
        },5000)

      } else return response.json();
    }).catch(err => {
      setMsgSucess(err);
      setLoading(false);
    });

    //reset();
  }

  return(
    
    <>
      {loading ? <div className="textloading font-face-bs"><LogoAnimation/></div> :

      bike?.map((bike, contador) => (
         <div key={contador}>

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />

        <Breadcrumbs home={"HOME"} linkhome={'/'} category={bike?.categoria?.name} linkproducts={'/products'} model={bike?.name}/>

        <Row>

          <Row className="titulobikemobile">
              <Col sm={12}>
                <h1 className="font-face-bs"> {bike?.name} | <span style={{color:"#f00"}}>{bike?.estoque?.size}"</span></h1>
              </Col>
          </Row>

          <Col sm={5}>
            <Row>
                <Col sm={12} className="links-slide font-face-bs">
                  {eye ? 
                  <FaRegEye style={{color:"#f00"}} className="icon-rede" onClick={() => eyeled()}/> :
                  <FaRegEye className="icon-rede" onClick={() => eyeled()}/>
                  }
                  {bookmarked ? 
                  <FaRegBookmark style={{color:"#f00", marginLeft: 10}} className="icon-rede" onClick={() => marked()}/> :
                  <FaRegBookmark  style={{marginLeft: 10}} className="icon-rede" onClick={() => marked()}/>
                  }
                </Col>
            </Row>

            <Row>               
              <Col sm={12} className="codigodabike font-face-bs">
                <h5><span>Cod.:</span>{bike?.codigo} <span> | Cor: </span> {bike.estoque?.color_text} <span> | Tamanho: </span> {bike?.estoque?.size}" </h5>                 
              </Col>
            </Row>

            <Row>
              <Col sm={12} className="slide insideproduct">
                <Carousel>
                  <Carousel.Item interval={3000}>
                    <img className={loadingimage ? "d-block w-100 blur" : "d-block w-100"} src={`${apibikes}${bike?.estoque?.img_src}`} alt={bike.name}/>
                  </Carousel.Item>
                </Carousel>
              </Col>

              <h5 className="font-face-bs" style={{textAlign: 'center'}}>Selecione a Cor e o Tamanho abaixo</h5>

              <div className="boxcoresdisponiveis">
                {umacordecadabike?.map((cor, index)=> (
                  <div key={cor.id} 
                    className={cor.estoque?.color_one_hexa === bike.estoque?.color_one_hexa ? "quadradodacorselect": "quadradodacor"}                
                    style={{backgroundColor: `${cor.estoque?.color_one_hexa}`}} 
                    onClick={() => {filtraBike(cor.id); setLoadingImage(true);}} 
                  />
                ))}
              </div>

              <div className="linhazinhaentrecoresetamanhos"><hr/></div>

              {/* <h5 className="font-face-bs" style={{textAlign: 'center'}}>Selecione o Tamanho</h5> */}

              <div className="tamanhosdisponiveis font-face-bs">
                 {outrostamanhosdisponiveis?.map((tamanho, index)=> ( 
                    <div key={tamanho.id} 
                      className={ tamanho.estoque?.size === bike?.estoque?.size ? `quadradodotamanhoselect ${tamanho.estoque?.estoque > 0 ?  tamanho.estoque?.size: "semestoque"} "`  : "quadradodotamanho"} 
                      style={{backgroundColor: '#fff'}} 
                      onClick={() => {filtraBike(tamanho.id); setLoadingImage(true);}}>
                      {tamanho.estoque?.size}" 
                    </div>
                  ))}
              </div>

            </Row> 

          </Col>

          <Col sm={1}>
          </Col>

          <Col sm={6}>

            <Row>
              <Col sm={8} className="titulobikedesktop">
                <h1 className="font-face-bs"> {bike?.name} | <span style={{color:"#f00"}}>{bike?.estoque?.size}"</span></h1>
              </Col>
              <Col sm={3} className="redesbike">
                <FaHeart style={lovely ? {color:"#f00"} : ""} className="icon-rede"  onClick={() => like()}/> 
                <FaShareAlt className="icon-rede"/> 
                <FaBell style={notification ? {color:"#f00"} : ""} className="icon-rede" onClick={() => {setModalShow(true);  setBikeModel(bike.name);  setColorBike(bike?.estoque?.color_text);  setSizeBike(bike?.estoque?.size); setNotification(true)}}/>                  
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <div className="stars">          
                  {stars.map((ranking) => (        
                    <FaStar style={{color:'#f00'}} key={ranking.id}/>   
                  ))}
                </div>
              </Col>
            </Row>

            <Row className="pricestock">
              <Col sm={12}>
                <span className="font-face-bs">Preço Sugerido</span>
              </Col> 
              <Col sm={6}>
                 <p className="price font-face-bs">{bike?.preco > 0 ? price(bike?.preco) : <span>R$ Consulte-nos</span>}</p>                
              </Col>
              <Col sm={6}>
                <p style={{fontWeight: 'bold'}}>Disponibilidade: 
                  <span style={{color: 'red'}}>{bike?.estoque?.estoque > 0 ? " Sim" : " Indisponível"}</span>
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={3}>
                <div className="coresdabike">
                {bike?.estoque?.color_one_hexa  &&  <p className="circulo"  style={{backgroundColor: `${bike?.estoque?.color_one_hexa}`  }}></p>}
                {bike?.estoque?.color_two_hexa  &&  <p className="circulo"  style={{backgroundColor: `${bike?.estoque?.color_two_hexa}`  }}></p>}
                {bike?.estoque?.color_three_hexa && <p className="circulo"  style={{backgroundColor: `${bike?.estoque?.color_three_hexa}`}}></p>}
                </div>
              </Col>
              <Col sm={7}/>               
            </Row>

            <div className="atributos_bike font-face-bs">    
              <Row>
                <Col sm={5}>
                  <p><img className="atributobike" src={`${apiatributos}/cor.jpg`} alt={bike?.estoque?.color_text}/> CORES: {bike?.estoque?.color_text}</p>
                </Col>
                <Col sm={1}></Col>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/quadro.jpg`} alt={bike?.atributos?.quadro}/>
                    {bike?.atributos?.quadro}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/selim.jpg`} alt={bike?.atributos?.selim}/>
                    {bike?.atributos?.selim}
                  </p>
                </Col>
                <Col sm={1}></Col>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/suspensao.jpg`} alt={bike?.atributos?.suspensao}/>
                    {bike?.atributos?.suspensao}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/guidao.jpg`} alt={bike?.atributos?.guidao}/>
                    {bike?.atributos?.guidao}
                  </p>
                </Col>
                <Col sm={1}></Col>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/pedivela.jpg`} alt={bike?.atributos?.pedivela}/>
                    {bike?.atributos?.pedivela}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/manopla.jpg`} alt={bike?.atributos?.manopla}/>
                    {bike?.atributos?.manopla}
                  </p>
                </Col>
                <Col sm={1}></Col>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/cassete.jpg`} alt={bike?.atributos?.cassete}/>
                    {bike?.atributos?.cassete}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/alavancas.jpg`} alt={bike?.atributos?.alavanca}/>
                    {bike?.atributos?.alavanca}
                  </p>
                </Col>
                <Col sm={1}></Col>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/corrente.jpg`} alt={bike?.atributos?.corrente}/>
                    {bike?.atributos?.corrente}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/freio.jpg`} alt={bike?.atributos?.freio}/>
                    {bike?.atributos?.freio}
                  </p>
                </Col>
                <Col sm={1}></Col>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/pedal.jpg`} alt={bike?.atributos?.pedal}/>
                    {bike?.atributos?.pedal}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/movimento_direcao.jpg`} alt={bike?.atributos?.movimento_direcao}/>
                    {bike?.atributos?.movimento_direcao}
                  </p>
                </Col>
                <Col sm={1}></Col>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/movimento_central.jpg`} alt={bike?.atributos?.movimento_central}/>
                    {bike?.atributos?.movimento_central}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/pneu.jpg`} alt={bike?.atributos?.pneu_dianteiro}/>
                    {bike?.atributos?.pneu_dianteiro} | {bike?.atributos?.pneu_traseiro}
                  </p>
                </Col>
                <Col sm={1}></Col>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/roda.jpg`} alt={bike?.atributos?.roda_dianteira}/>
                    {bike?.atributos?.roda_dianteira}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/cubo.jpg`} alt={bike?.atributos?.cubo_dianteiro}/>
                    {bike?.atributos?.cubo_dianteiro}
                  </p>
                </Col>
                <Col sm={1}></Col>
                <Col sm={5}>
                  <p>
                    <img className="atributobike" src={`${apiatributos}/cubo.jpg`} alt={bike?.atributos?.cubo_traseiro}/>
                    {bike?.atributos?.cubo_traseiro}
                  </p>
                </Col>
              </Row>
            </div>

            <Row>
              <Col sm={11}>
                <p>{bike.description}</p>
              </Col>
            </Row>

            <Row>
              <Col sm={8} className="buttonsbike">
                <Button className="btn btn-comprar" variant="danger" 
                    onClick={() => {
                      setModalShow(true); 
                      setBikeModel(bike?.name); 
                      setColorBike(bike?.estoque?.color_text); 
                      setSizeBike(bike?.estoque?.size)}}
                    > 
                  <FaWhatsapp/> Comprar
                </Button>
                <Button variant="outline-secondary" className="btn btn-maisdetalhes">
                  mais detalhes
                </Button>

                {currentUser && 
                 <Button className="btn btn-comprar" variant="danger" onClick={showupdatebox} style={{marginLeft:10}}>
                     Atualizar a Bike
                  </Button>
                }
              </Col>

            </Row>

            <Row className="description">
              <Col sm={11}>
              <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="home" title="Descrição Técnica">
                  <p>Descrição Técnica da Bike</p>
                  <p>{bike.description}</p>
                </Tab>
                <Tab eventKey="avaliacoes" title="Avaliações">
                  <p>{bike.description}</p>
                </Tab>
                <Tab eventKey="comocomprar" title="Comprar" disabled >
                  <p>{bike.description}</p>
                </Tab>
              </Tabs>
              </Col>

            </Row>
          
          </Col>

        </Row>
      
        </div>
      ))}

      <Container className="updtatecontent" style={{marginTop:50, paddingBottom:100}}>

        {currentUser && updatebox &&         
          <Formulario onSubmit={handleSubmit} ref={formRef} initialData={bike}>

            <Row>
              <Col sm={6}>
                <Input label="Código" name="codigo"/>
              </Col>
              <Col sm={6}>
                <Input label="Modelo" name="name"/> 
              </Col>
            </Row> 

            <Row>
              <Col sm={6}>
                <Input label="Categoria" name="categoria.name"/> 
              </Col>
              <Col sm={6}>
                <Input label="Descrição" name="description"/>
              </Col>
            </Row> 
            
            <Row>
              <Col sm={6}>
                <Input label="Quadro" name="atributos.quadro"/>      
              </Col>
              <Col sm={6}>
                <Input label="Cor" name="estoque.color_text"/>
              </Col>
            </Row> 

            <Row>
              <Col sm={6}>
                <Input label="Tamanho" name="estoque.size"/>
              </Col>
              <Col sm={6}>
                <Input label="Suspensão" name="atributos.suspensao"/>
              </Col>
            </Row> 

            <Row>
              <Col sm={6}>
                <Input label="Guidao" name="atributos.guidao"/>    
              </Col>
              <Col sm={6}>
                <Input label="Manopla" name="atributos.manopla"/>
              </Col>
            </Row> 

            <Row>
              <Col sm={6}>
                <Input label="Movimento direção" name="atributos.movimento_direcao"/>
              </Col>
              <Col sm={6}> 
                <Input label="Movimento central" name="atributos.movimento_central"/>
              </Col>
            </Row> 

            <Row>
              <Col sm={6}>
                <Input label="Câmbio Dianteiro" name="atributos.cambio_dianteiro"/>    
              </Col>
              <Col sm={6}>
                <Input label="Câmbio Traseiro" name="atributos.cambio_traseiro"/>
              </Col>
            </Row> 

            <Row>
              <Col sm={6}>
                <Input label="Alavancas" name="atributos.alavanca"/>
              </Col>
              <Col sm={6}>
              <Input label="Freio" name="atributos.freio"/>
              </Col>
            </Row> 

            <Row>
              <Col sm={6}>
                <Input label="Selim" name="atributos.selim"/>      
              </Col>
              <Col sm={6}>
                <Input label="Canote" name="atributos.canote"/>
              </Col>
            </Row> 

            <Row>
              <Col sm={6}>
                <Input label="Pedal" name="atributos.pedal"/>      
              </Col>
              <Col sm={6}>
                <Input label="Pedivela" name="atributos.pedivela"/>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Input label="Corrente" name="atributos.corrente"/>
              </Col>
              <Col sm={6}>
                <Input label="Cassete" name="atributos.cassete"/>
              </Col>
            </Row>
          
            <Row>
              <Col sm={6}>
              <Input label="Roda Dianteira" name="atributos.roda_dianteira"/>
              </Col>
              <Col sm={6}>
              <Input label="Cubo Dianteiro" name="atributos.cubo_dianteiro"/>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Input label="Roda Traseira" name="atributos.roda_traseira"/>
              </Col>
              <Col sm={6}>
                <Input label="Cubo Traseiro" name="atributos.cubo_traseiro"/>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Input label="Pneu Dianteiro" name="atributos.pneu_dianteiro"/>
              </Col>
              <Col sm={6}>
                <Input label="Pneu Traseiro" name="atributos.pneu_traseiro"/>
              </Col>
            </Row>

            <Row>
              <Col sm={3}>
                <Input label="Preço" name="preco"/>
              </Col>
              <Col sm={2}>
                <Input label="Desconto" name="desconto"/>
              </Col>
              <Col sm={3}>
                <Input label="Estoque" name="estoque.estoque"/>
              </Col>
              <Col sm={2}>
                <Input label="Home (0 ou 1)" name="home"/>
              </Col>
              <Col sm={2}>
                <Input label="Produtos (0 ou 1)" name="destaque"/>
              </Col>

            </Row>

            <Row>
              <Col sm={4}>
                <Input label="Cor Primária" name="estoque.color_one_hexa"/>
              </Col>
              <Col sm={4}>
                <Input label="Cor Secundária" name="estoque.color_two_hexa"/>
              </Col>
              <Col sm={4}>
                <Input label="Cor Terciária" name="estoque.color_three_hexa"/>
              </Col>
            </Row>



            <Row>
              <Col sm={12} style={{textAlign: 'center', padding:10,}} className="font-face-bs">
                {msgsucess}
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <button 
                type="submit" 
                className="btn btn-gobike" 
                style={{width:'100%', fontWeight:'bold'}}>
                  Atualizar Bike
                </button>
              </Col>
            </Row>
        
          </Formulario>
        }
      </Container>

    </>

  );
}

