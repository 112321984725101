import { Row, Col } from 'react-bootstrap/';
import { Breadcrumbs } from '../../components/Breadcrumbs';

import { BikeAnimation } from '../../components/BikeAnimation';

import empresa from '../../assets/vercelli_ettore.jpg'

import './empresa.css';

export function ComponenteEmpresa() {
  
  return (
    <>
    <Breadcrumbs home={"HOME"} linkhome={'/'} category={"EMPRESA"} model={"VERCELLI"}/>

    <Row>
      <Col sm={10} className="font-face-bs titleempresa">
        <h3>Quem somos</h3>
      </Col>
    </Row>

    <Row className="titleempresa">

      <Col sm={6} className="animacao_bike">
      <img className="d-block w-100" src={empresa} alt="Empresa Vercelli" />
        <BikeAnimation/>
        
      </Col>
      <Col sm={6}>
        <p></p>
        <p>Quando você está na estrada, você quer estar no controle.<br/> Você quer estar confortável e seguro.  Você quer sentir que tem o poder de chegar onde precisa com facilidade e rapidez.</p>
        <p>Bike Vercelli é a maneira de conseguir tudo isso e muito mais. <br/> A Bike Vercelli é uma bicicleta de luxo que leva em consideração todas as suas necessidades.</p>
        <p>Com o seu design patenteado e componentes topo de gama, a Bike Vercelli proporciona-lhe uma condução suave, quer esteja a deslocar-se ao trabalho ou a conduzir por diversão. <br/> 
        Também é fácil de levar com você: quando dobrado, é apenas um pouco maior do que a maioria das malas!</p>
        <p>Leve-o com você para qualquer lugar - das ruas da cidade às estradas do campo - e saiba que o Bike Vercelli 
          sempre estará lá para você quando você mais precisar.</p>
        <p>Uma bicicleta não é apenas uma bicicleta.É o meio para levá-lo aonde você precisa ir.</p>
        <p>É o veículo que o levará pelas ruas da sua cidade e por novos mundos.<br/>
        É uma extensão da sua personalidade e uma expressão de quem você é, mesmo quando está no modo silencioso.<br/>
        É por isso que criamos nossas bicicletas apenas com os melhores materiais e componentes, para que 
        possam conectar você ao mundo ao seu redor com estilo e mantê-lo seguro ao fazê-lo.</p>
      </Col>


    </Row>

    </>

)};