import { Row, Col } from 'react-bootstrap/';

import './title.css'

export function Title(props) {
  return(
    <Row className="headertitle font-face-bs">
      <Col sm={12}>
        <h2 className="title">{props.title}</h2>
        <p className="subtitle">{props.subtitle}</p>
      </Col>
    </Row>
    
  )
}