import { Container } from 'react-bootstrap/';

import { Header } from '../../components/Header';
import { ComponenteEmpresa } from '../../components/Empresa';

import Image from "../../assets/bg.png";
import { MenuMobile } from '../../components/MenuMobile';

export function Empresa() {
  
  return (
    <Container fluid style={{backgroundImage:'url(' + Image + ')', backgroundSize:'cover', overflow:'hidden', height:'100vh', width:'100vw', overflowY: 'scroll',}}>

      <Header/>
      <MenuMobile/>
      <Container>
        <ComponenteEmpresa/>
      </Container>


    </Container>

)}

