import React, { useContext, useState, useEffect } from "react"
import api from '../services/api';

const CategoriaContext = React.createContext()

export function useCategorias() {
  return useContext(CategoriaContext);
}

export function CategoriaProvider({ children }) {
  const [categoriasData, setCategoriasData] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
      async function getCategoriasSql() {
        const response = await api.get('/categorias');
        if(isSubscribed){
          setCategoriasData(response.data);
        }        
      };
    getCategoriasSql();
    return () => isSubscribed = false
    // eslint-disable-next-line 
  }, []);

  categoriasData?.sort(function (a, b) {
    return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0);
  });

  const value = {
    categoriasData,
  }

  return (
    <CategoriaContext.Provider value={value}>
      {children}
    </CategoriaContext.Provider>
  )
}