import Lottie from 'react-lottie-player'
import lottieJson from '../../lotties/bicycle.json';

export function BikeAnimation(){

  return(
    <Lottie
      loop
      animationData={lottieJson}
      play
      style={{ maxWidth: 500}}
    />
  )
};

//https://github.com/mifi/react-lottie-player