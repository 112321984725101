import {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { useAutoAnimate } from '@formkit/auto-animate/react'

import api from '../../services/api';

import { Card } from '../../components/Products/Card/';
import { LogoAnimation } from '../../components/LogoAnimation';

import './produtoshome.css';

export function ProdutosHome() {
  
  const history = useHistory();
  const [animationParent] = useAutoAnimate({duration:300});

  const [loading, setLoading] = useState(true);
  const [produtos, setProdutos] = useState([]);

  useEffect(() => {
    let isSubscribed = true
   
    async function getProdutos() {
      const response = await api.get('/produtos/');
      if (isSubscribed) {
        setProdutos(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000)

      }
    };
    getProdutos();
    return () => isSubscribed = false
  }, []);

  const produtosnahome = produtos.filter((produto) => produto.home === 1)

  function handleGoToBikes(){
    history.push("/products");
  }

  return(
    <div className="product__wrapper" style={{marginTop:30, paddingBottom:100}}>

      <div className="card__container" ref={animationParent}> 
        {loading ?  <div className="textloading font-face-bs"><LogoAnimation/></div> :
          produtosnahome?.map((p, index) => (
            <Card key={index} product={p}/>
        ))}
      </div>

      <div id="buttonhomemaismodelos">
        <button 
          className="btn btn-gobike"
          onClick={handleGoToBikes}          
          style={{width:'50%', marginTop: 50}}>
          Todos Modelos
        </button>
      </div>
      
    </div>

  )
}