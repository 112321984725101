import { Container } from 'react-bootstrap/';

import { Header } from '../../components/Header';
import { SliderHome } from '../../components/SliderHome/';
import { SliderMobile } from '../../components/SliderMobile/';
//import { BikesHome } from '../../components/BikesHome';
import { ProdutosHome } from '../../components/ProdutosHome';

import { MenuMobile } from '../../components/MenuMobile';

import Image from "../../assets/bg.png";

export function Home() {
  
  return (

    <Container fluid style={{backgroundImage:'url(' + Image + ')', backgroundSize:'cover', overflow:'hidden', height:'100vh', width:'100vw', overflowY: 'scroll',}}>

      <Header/>

      <MenuMobile/>

      <SliderMobile/>

      <SliderHome/>

      <Container>
        {/* <BikesHome/> */}
        <ProdutosHome/>
        
      </Container>

    </Container>      
  );
}